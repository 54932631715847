<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
const modules = [Pagination, Autoplay];
defineProps(["data"]);
</script>

<template>
  <swiper
    :loop="true"
    :pagination="true"
    :modules="modules"
    class="mySwiper"
    :autoplay="{
      delay: 3000,
      disableOnInteraction: true,
    }"
  >
    <swiper-slide v-for="(image, index) in data" :key="index">
      <img :src="image">
    </swiper-slide>
  </swiper>
</template>