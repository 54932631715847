<script setup>
//vue
import { ref, computed, onMounted } from "vue";
//vue-router
import { RouterLink, useRouter } from "vue-router";
const router = useRouter();
//components
import PaymentCard from "@/components/shared/PaymentCard.vue";
//store
import { usePaymentStore } from "@/store/stores";

const $P = usePaymentStore();

const paymentCards = computed(() => $P.paymentCards);
const loaded = ref(false);
const error = ref(false);

onMounted(async() => {
  const res = await $P.getAllCards();
  error.value = !res;
  loaded.value = true;
});
</script>

<template>
  <div class="loading" v-if="!loaded">
    <img class="manijita" src="../../../assets/logos/manijita.svg">
    <img class="bolsa" src="../../../assets/logos/bolsa.svg">
  </div>
  <div v-else class="payments">
    <div class="payments-title">
      <h1>Tarjetas</h1>
      <RouterLink :to="`/profile/info/add/paymentCard`" class="blue-button"><v-icon>mdi-plus</v-icon></RouterLink>
    </div>

    <div v-if="!error">
      <div v-if="paymentCards?.length === 0" class="empty-data">
        <img src="../../../assets/shoesize-modal-blue.svg" alt="svg">
        <span>Agrega tu tarjeta tocando "+"</span>
        <p>Todavia no agregaste ninguna tarjeta.</p>
      </div>
  
      <div v-else class="payments-cards">
        <PaymentCard v-for="(cardInfo, index) in paymentCards" :key="index" :data="cardInfo" :delete="true" />
      </div>
    </div>

    <div v-else class="empty-products">
      <img src="../../../assets/svgs/search_one.svg" alt="svg">
      <div>
        <span>Error al cargar las tarjetas ⚠️</span>
        <p>Hubo un problema inesperado al cargar la información de tus tarjetas. Por favor inténtalo de nuevo más tarde. 🙏</p>
      </div>
      <button @click="router.push('/')" class="blue-button">Ir al inicio</button>
    </div>
    
  </div>
</template>