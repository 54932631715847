import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { fetchWrapper } from "@/helpers/fetchWrapper";
import { Device } from "@capacitor/device";
import { Share } from "@capacitor/share";
import { PushNotifications } from "@capacitor/push-notifications";
import { useUserStore } from "@/store/stores";
import { Capacitor } from "@capacitor/core";

export const useCapacitorStore = defineStore("capacitor", () => {
  const $U = useUserStore();
  const deviceInfo = ref(null);
  const downloadModal = ref(true);
  const webValidate = computed(() => {
    return !Capacitor.isNativePlatform() && (deviceInfo?.value.operatingSystem === "ios" || deviceInfo?.value.operatingSystem === "android");
  });

  const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === "prompt") {
      permStatus = await PushNotifications.requestPermissions();
    }
    if (permStatus.receive !== "granted") {
      throw new Error("User denied permissions!");
    }
    await PushNotifications.register();
  };

  const addListeners = async () => {
    await PushNotifications.addListener("registration", token => {
      if($U.currentUser) {
        console.info("Registration token: ", token.value);
        sendToken(token.value);
      }
    });
    await PushNotifications.addListener("registrationError", err => {
      console.error("Registration error: ", err.error);
    });
    await PushNotifications.addListener("pushNotificationReceived", notification => {
      console.log("Push notification received: ", notification);
    });
    await PushNotifications.addListener("pushNotificationActionPerformed", notification => {
      console.log("Push notification action performed", notification.actionId, notification.inputValue);
    });
  };

  const logDeviceInfo = async () => {
    try {
      const info = await Device.getInfo();
      deviceInfo.value = info;
      console.log("device", deviceInfo.value);
    } catch(e) {
      console.log(e);
    }
  };

  const share = async (url) => {
    try {
      await Share.share({url});
    } catch(e) {
      console.log(e);
    }
  };

  const sendToken = async (token) => {
    const data = {
      userClientId: $U.currentUser?.id,
      token: token
    };
    try {
      await fetchWrapper.post("usersFCMTokens", data);
    } catch(e) {
      console.log(e);
    }
  };

  return {
    deviceInfo,
    downloadModal,
    webValidate,
    registerNotifications,
    addListeners,
    logDeviceInfo,
    share
  };
}, {
  persist: true
}); 