<script setup>
/* eslint-disable */
//vue
import { ref, computed, reactive, watch, onMounted } from "vue";
//vue-router
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
//stores
import { usePaymentStore, useUserStore } from "@/store/stores";
const { newCard } = usePaymentStore();
const $U = useUserStore();
//helpers
import { addSlash, addSpaces, removeSpaces, validateCard, validarInput } from "@/helpers/cardHelpers";
//Variables
const step = ref(0);
const disabled = ref(false)
const currentYear = new Date().getFullYear() - 2000;

const cardData = reactive({
  number: "",
  cardExpiration: "",
  securityCode: "",
  holderName: "",
  holderIdentification: "",
  company: null,
  option: null
});

//Functions
const warnDisabled = () => {
  disabled.value = true
  setTimeout(() => {
    disabled.value = false
  }, 1500)
}

const addCard = async () => {
  const cardDataToSend = {
    userClientId: $U.currentUser.id,
    isDebitCard: cardData.option === "debit",
    isCreditCard: cardData.option === "credit",
    number: removeSpaces(cardData.number),
    expirationMonth: cardData.cardExpiration.split("/")[0],
    expirationYear: cardData.cardExpiration.split("/")[1],
    securityCode: cardData.securityCode,
    holderName: cardData.holderName.toUpperCase(),
    holderIdentification: cardData.holderIdentification,
    company: cardData.company
  }
  console.log(cardDataToSend);
  await newCard(cardDataToSend);
  const path = computed(() => route.path)
  const pathParts = path.value.split('/');
  if (pathParts[1] === "profile") {
    router.push(`/profile/info/my-cards`);
  } else {
    router.push(`/cart/purchase/payment-options`);
  }
}

watch(cardData, () => {
  cardData.number = addSpaces(cardData.number, cardData.company);
  if(cardData.cardExpiration?.substring(2, 3) !== "/" && cardData.cardExpiration > 2) cardData.cardExpiration = addSlash(cardData.cardExpiration);
  cardData.company = validateCard(cardData.number.substring(0, 9));
  cardData.holderName = cardData.holderName.toUpperCase();
});

onMounted(() => {
  const input = document.getElementsByClassName("input-number")
  for (const e of input) {
    e.addEventListener('keypress', validarInput);
  }
})
</script>

<template>
  <div class="addCard">
    <div :class="cardData.company" class="paymentCard">
      <div class="card-top">
        <p v-if="cardData.option === 'credit'">CREDITO</p>
        <p v-else-if="cardData.option === 'debit'">DEBITO</p>
        <p v-else>-</p>
        <div :class="{nobg: cardData.company}" class="card-image">
          <img v-show="cardData.company === 'Visa'" src="../../../assets/logos/visa-w.png" alt="visa">
          <img v-show="cardData.company === 'Master'" src="../../../assets/logos/master-w.png" alt="master">
          <img v-show="cardData.company === 'Amex'" src="../../../assets/logos/amex-w.png" alt="amex">
        </div>
      </div>

      <p class="card-number">{{ cardData.number === "" ? "**** **** **** ****" : cardData.number }}</p>

      <div class="card-info">
        <p>{{ cardData.holderName === "" ? "Titular de la tarjeta" : cardData.holderName }}</p>
        <p>{{ cardData.cardExpiration === "" ? "MM/AA" : cardData.cardExpiration }}</p>
      </div>
    </div>

    <div class="paymentForm">
      <div class="form">
        <v-radio-group
          :class="{active: step === 0, prev: step === 1, shake: disabled}"
          v-model="cardData.option"
          inline
        >
          <v-radio label="CREDITO" value="credit"></v-radio>
          <v-radio label="DEBITO" value="debit"></v-radio>
        </v-radio-group>
    
        <v-text-field
          :class="{active: step === 1, prev: step === 2, next: step === 0, shake: disabled}"
          v-model="cardData.number"
          :maxlength="cardData.company === 'Amex' ? 17 : 19"
          label="Número de tarjeta"
          variant="outlined"
          hide-details="true"
          class="input-number"
        ></v-text-field>
    
        <v-text-field
          :class="{active: step === 2, prev: step === 3, next: step === 1, shake: disabled}"
          v-model="cardData.holderName"
          label="Nombre y apellido"
          variant="outlined"
          hide-details="true"
        ></v-text-field>
    
        <v-text-field
          :class="{active: step === 3, prev: step === 4, next: step === 2, shake: disabled}"
          v-model="cardData.cardExpiration"
          maxlength="5"
          label="Fecha de vencimiento"
          variant="outlined"
          hide-details="true"
          class="input-number"
        ></v-text-field>
    
        <v-text-field
          :class="{active: step === 4, prev: step === 5, next: step === 3, shake: disabled}"
          v-model="cardData.securityCode"
          maxlength="4"
          label="Código de seguridad"
          variant="outlined"
          hide-details="true"
          class="input-number"
        ></v-text-field>
    
        <v-text-field
          :class="{active: step === 5, next: step === 4, shake: disabled}"
          v-model="cardData.holderIdentification"
          label="DNI del titular de la tarjeta"
          maxlength="8"
          variant="outlined"
          hide-details="true"
          class="input-number"
        ></v-text-field>
      </div>

      <div class="buttons">

        <button
        v-if="step >= 1"
        :disabled="disabled"
        @click.prevent="step--"
        class="blue-button">Atras</button>

        <button
        v-else
        @click.prevent="router.back()"
        class="blue-button">Atras</button>

        <button
        v-if="step === 0"
        :disabled="disabled"
        @click.prevent="cardData.option === null ? warnDisabled() : step++"
        class="blue-button nextButton">Siguiente</button>

        <button
        v-if="step === 1"
        :disabled="disabled"
        @click.prevent="(cardData.number.length === 19 || cardData.number.length === 17) ? step++ : warnDisabled()"
        class="blue-button">Siguiente</button>

        <button
        v-if="step === 2"
        :disabled="disabled"
        @click.prevent="cardData.holderName === '' ? warnDisabled() : step++"
        class="blue-button">Siguiente</button>

        <button
        v-if="step === 3"
        :disabled="disabled"
        @click.prevent="( 
          ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].includes(cardData.cardExpiration.split('/')[0].toString()) &&
          cardData.cardExpiration.split('/')[1] >= currentYear
          ) ? step++ : warnDisabled()"
        class="blue-button">Siguiente</button>

        <button
        v-if="step === 4"
        :disabled="disabled"
        @click.prevent="(cardData.securityCode.length === 3 || cardData.securityCode.length === 4) ? step++ : warnDisabled()"
        class="blue-button">Siguiente</button>

        <button
        v-if="step === 5"
        :disabled="disabled"
        @click.prevent="cardData.holderIdentification === '' ? warnDisabled() : addCard()"
        class="blue-button">Agregar</button>
      </div>

      <span v-if="disabled && step === 0">Debes seleccionar una opcion</span>
      <span v-if="disabled && (step === 1 || step === 4 || step === 5)">El dato ingresado no es valido</span>
      <span v-if="disabled && step === 2">Debes completar con lo pedido</span>
      <span v-if="disabled && step === 3">La fecha ingresada no es valida</span>

    </div>
  </div>
</template>