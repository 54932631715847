<script>
/* eslint-disable */
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules';

//components
import PaymentCard from "./shared/PaymentCard.vue";

//stores
import { mapState } from "pinia";
import { useWindowStore } from '@/store/window';

export default {
  props: {
    data: Array
  },
  components: {
    Swiper,
    SwiperSlide,
    PaymentCard,
  },
  computed: {
    ...mapState(useWindowStore, ["windowWidth"])
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;

      // Escucha el evento 'slideChange' de Swiper
      this.swiper.on("slideChange", () => {
        const activeIndex = this.swiper.realIndex;
        const activeItem = this.data[activeIndex];
        console.log(activeItem);
        this.$emit("sendInfo", activeItem);
      });
    },
  },
  setup() {
    return {
      modules: [EffectCoverflow, Pagination],
    };
  },
};
</script>

<template>
  <swiper @swiper="onSwiper"
  :spaceBetween="10"
  :grabCursor="true"
  :centeredSlides="true"
  :slidesPerView="2"
  :pagination="true"
  :modules="modules">
    <swiper-slide v-for="card in data" :key="card.id">
      <PaymentCard :delete="true" :data="card" />
    </swiper-slide>
  </swiper>
</template>