<script setup>
/* eslint-disable */
//vue
import { ref, onMounted } from "vue";
//vue-router
import { RouterLink } from "vue-router";
//components
import PurchasePayment from "./PurchasePayment.vue";
//stores
import { usePurchaseStore, useWindowStore } from "@/store/stores";
//helpers
import { textDate, fullTextDate } from "@/helpers/dateFormatter";
import { divideArray } from "@/helpers/arrayDivider";
import { capitalize } from "@/helpers/capitalize";

const { getOrders, deleteOrder } = usePurchaseStore();
const $W = useWindowStore();

const loaded = ref(false);
const purchases = ref([]);
const purchasesCount = ref(0);
const page = ref(1);

const payment = ref(false);
const paymentData = ref(null);
const close = (info) => {
  payment.value = info
};

onMounted(async () => {
  const res = await getOrders();
  console.log(res);
  purchasesCount.value = res.count;
  purchases.value = divideArray(res.orders, 5);
  loaded.value = true;
});

const pay = (data) => {
  paymentData.value = data;
  console.log("data", data);
  payment.value = true;
}

const dltOrder = async (orderId) => {
  const res = await deleteOrder(orderId);
  if(res) {
    const res = await getOrders();
    purchasesCount.value = res.count;
    purchases.value = divideArray(res.orders, 5);
  }
};

</script>

<template>
  <div v-if="!loaded" class="loading">
    <img class="manijita" src="../../../assets/logos/manijita.svg">
    <img class="bolsa" src="../../../assets/logos/bolsa.svg">
  </div>
  <div v-else-if="purchases.length > 0" class="profile-purchases__container">
    <PurchasePayment v-if="payment" :data="paymentData" @close="close" />
    <span v-if="purchasesCount > 5">{{ 1 + 5 * (page - 1) }} - {{ purchases[page - 1]?.length + 5 * (page - 1) }} de {{ purchasesCount }} compras</span>
    <div v-if="$W.windowWidth > 768" class="profile-purchases__items">
      <div v-for="purchase in purchases[page - 1]" :key="purchase.id" class="profile-purchases__item">
        <div class="item-date">
          <template v-if="!purchase.isPaid">
            <span style="color: gray">Confirmación de pago</span>
            <strong>Realizá el pago para continuar</strong>
          </template>

          <template v-else-if="purchase.status === 'CANCELED'">
            <span style="color: red">Pedido cancelado</span>
          </template>

          <template v-else-if="purchase.status === 'FAILED'">
            <span style="color: red">Hubo un error en el pedido</span>
            <strong>Comunicate a nuestro whatsapp 2236699751</strong>
          </template>

          <template v-else-if="purchase.pickUpStore">
            <template v-if="purchase.status === 'MERCHANT-PREPARING-ORDER'">
              <span>La tienda esta preparando tu pedido</span>
              <strong>Vas a retirar en {{ purchase.userMerchantAddress.mainStreet + " " + purchase.userMerchantAddress.number }}</strong>
            </template>
            <template v-else-if="purchase.status === 'READY-TO-RETIRE-STORE'">
              <span style="color: green">Tu pedido esta listo para retirar en tienda</span>
              <strong>Retirá en {{ purchase.userMerchantAddress.mainStreet + " " + purchase.userMerchantAddress.number }}</strong>
            </template>
            <template v-else>
              <span style="color: green">Retiraste tu pedido</span>
              <strong>¡Que lo disfrutes!</strong>
            </template>
          </template>

          <template v-else-if="purchase.branchId">
            <template v-if="purchase.status === 'MERCHANT-PREPARING-SHIPMENT'">
              <span>La tienda esta preparando el envio 😉</span>
            </template>
            <template v-else-if="purchase.status === 'SHIPMENT-TRAVELING'">
              <span>Tu pedido esta viajando a OCA 🚚</span>
            </template>
            <template v-else-if="purchase.status === 'READY-TO-RETIRE-SHIPMENT'">
              <span>Tu pedido esta listo para ser retirado por OCA 😀</span>
            </template>
            <template v-else-if="purchase.status === 'SHIPMENT-FAILED'">
              <span>Hubo un error en el envio</span>
              <strong>Comunicate a nuestro whatsapp 2236699751</strong>
            </template>
            <template v-else>
              <span>Retiraste tu pedido ❤️</span>
            </template>
          </template>

          <template v-else>
            <template v-if="purchase.status === 'MERCHANT-PREPARING-SHIPMENT'">
              <span>La tienda esta preparando el envio 😉</span>
            </template>
            <template v-else-if="purchase.status === 'SHIPMENT-TRAVELING'">
              <span>Tu pedido esta viajando a tu casa 🚚</span>
            </template>
            <template v-else>
              <span>El pedido fue entregado ❤️</span>
            </template>
          </template>
        </div>

        <div v-for="(product, index) in purchase?.orderItems" :key="index" class="item-data">

          <div class="info-container">
            <img :src="(product?.articleVariant.images?.length > 0 ? product?.articleVariant.images : product?.articleVariant.article.images)[0]">
            <div class="shipping">
              <div class="shipping-info">
                <p>{{ product.articleVariant.name ?? product.articleVariant.article.name }}</p>
                <span v-for="(value, index) in product?.articleVariant.values" :key="index">{{ capitalize(product?.articleVariant.article.attributes[index]) }}: {{ value.toUpperCase() }}</span>
              </div>
            </div>
          </div>

        </div>
        <div class="item-date bottom">
          <p>{{ textDate(purchase.createdAt) }}</p>
          <div v-if="!purchase.isPaid" class="btns">
            <div @click="pay(purchase)" class="primary">Realizar pago</div>
            <div @click="dltOrder(purchase.id)" class="secondary" style="background-color: #dd0707; color: white">Eliminar orden</div>
          </div>
          <div v-else class="btns">
            <RouterLink :to="`my-purchases/${purchase.id}`" class="primary">Ver compra</RouterLink>
            <RouterLink :to="`/merchant/${purchase?.userMerchant.id}`" class="secondary">Volver a comprar</RouterLink>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="profile-purchases__items">
      <div class="profile-purchases__item" v-for="purchase in purchases[page - 1]" :key="purchase.id">
        
        <div class="item-date top">
          
          <template v-if="!purchase.isPaid">
            <span style="color: gray">Confirmación de pago</span>
            <strong>Realizá el pago para continuar</strong>
          </template>

          <template v-else-if="purchase.status === 'CANCELED'">
            <span style="color: red">Pedido cancelado</span>
          </template>

          <template v-else-if="purchase.status === 'FAILED'">
            <span style="color: red">Hubo un error en el pedido</span>
            <strong>Comunicate a nuestro whatsapp 2236699751</strong>
          </template>

          <template v-else-if="purchase.pickUpStore">
            <template v-if="purchase.status === 'MERCHANT-PREPARING-ORDER'">
              <span>La tienda esta preparando tu pedido</span>
              <strong>Vas a retirar en {{ purchase.userMerchantAddress.mainStreet + " " + purchase.userMerchantAddress.number }}</strong>
            </template>
            <template v-else-if="purchase.status === 'READY-TO-RETIRE-STORE'">
              <span style="color: green">Tu pedido esta listo para retirar en tienda</span>
              <strong>Retirá en {{ purchase.userMerchantAddress.mainStreet + " " + purchase.userMerchantAddress.number }}</strong>
            </template>
            <template v-else>
              <span style="color: green">Retiraste tu pedido</span>
              <strong>¡Que lo disfrutes!</strong>
            </template>
          </template>

          <template v-else-if="purchase.branchId">
            <template v-if="purchase.status === 'MERCHANT-PREPARING-SHIPMENT'">
              <span>La tienda esta preparando el envio 😉</span>
            </template>
            <template v-else-if="purchase.status === 'SHIPMENT-TRAVELING'">
              <span>Tu pedido esta viajando a OCA 🚚</span>
            </template>
            <template v-else-if="purchase.status === 'READY-TO-RETIRE-SHIPMENT'">
              <span>Tu pedido esta listo para ser retirado por OCA 😀</span>
            </template>
            <template v-else-if="purchase.status === 'SHIPMENT-FAILED'">
              <span>Hubo un error en el envio</span>
              <strong>Comunicate a nuestro whatsapp 2236699751</strong>
            </template>
            <template v-else>
              <span>Retiraste tu pedido ❤️</span>
            </template>
          </template>

          <template v-else>
            <template v-if="purchase.status === 'MERCHANT-PREPARING-SHIPMENT'">
              <span>La tienda esta preparando el envio 😉</span>
            </template>
            <template v-else-if="purchase.status === 'SHIPMENT-TRAVELING'">
              <span>Tu pedido esta viajando a tu casa 🚚</span>
            </template>
            <template v-else>
              <span>El pedido fue entregado ❤️</span>
            </template>
          </template>

        </div>

        <template v-for="product in purchase?.orderItems">
          <RouterLink :to="!purchase.isPaid ? '' : `my-purchases/${purchase.id}`" class="item-data">

            <div class="info-container">
              <img :src="(product?.articleVariant.images?.length > 0 ? product?.articleVariant.images : product?.articleVariant.article.images)[0]">
              <div class="shipping">
                <div class="shipping-info">
                  <p>{{ product.articleVariant.name ?? product.articleVariant.article.name }}</p>
                  <span v-for="(value, index) in product?.articleVariant.values" :key="index">{{ capitalize(product?.articleVariant.article.attributes[index]) }}: {{ value.toUpperCase() }}</span>
                </div>
              </div>
            </div>

          </RouterLink>
        </template>

        <div class="item-date bottom">
          <p>{{ fullTextDate(purchase.createdAt) }}</p>
          <a v-if="!purchase.isPaid" @click="pay(purchase)">Realizar pago</a>
          <RouterLink v-else :to="`/merchant/${purchase.userMerchantId}`">Volver a comprar</RouterLink>
        </div>
      </div>
    </div>
    <v-pagination
      v-if="purchasesCount > 5"
      v-model="page"
      :length="Math.ceil(purchasesCount / 5)"
      :total-visible="$W.windowWidth > 768 ? 5 : 3"
    ></v-pagination>
  </div>
  <div v-else class="empty-data">
    <img src="../../../assets/shoesize-modal-blue.svg" alt="svg">
    <span>No hiciste ninguna compra aun</span>
    <p>Hace la compra de algun producto y aca te van a aparecer todos los datos de la misma.</p>
  </div>
</template>