import { ref } from "vue";
import { defineStore } from "pinia";
import { useUserStore, usePopStore } from "@/store/stores";
import { fetchWrapper } from "@/helpers/fetchWrapper";

export const useAddressStore = defineStore("address", () => {
  const $P = usePopStore();
  const $U = useUserStore();

  const addresses = ref();
  const branches = ref([]);
  const branchId = ref(0);

  const getAllAddresses = async () => {
    try {
      const res = await fetchWrapper.get("clients/users/addresses/userClient?isDeleted=false");
      addresses.value = res.data;
      return res.data;
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Hubo un error al cargar los domicilios", 0);
    }
  };

  const getAllBranches = async (userPostalCode) => {
    try {
      const res = await fetchWrapper.post("oca/getSucursales", {
        codigoPostal: userPostalCode,
        isClient: true,
        isMerchant: false
      });
      branches.value = res.data;
      return res.data;
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Hubo un error al cargar los domicilios del retiro", 0);
    }
  };

  const getAddress = async (addressId) => {
    try {
      const res = await fetchWrapper.get(`clients/users/addresses/${addressId}`);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  };

  const newAddress = async (address) => {
    try {
      const res = await fetchWrapper.post("clients/users/addresses", address);
      await updateMainAddressId(res.data.id);
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Hubo un error al crear el domicilio", 0);
    }
  };

  const updateAddresses = async (addressId, newData) => {
    try {
      const res = await fetchWrapper.put(`clients/users/addresses/${addressId}`, newData);
      await updateMainAddressId(res.data.id);
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Hubo un error al actualizar la direccion", 0);
    }
  };

  const deleteAddress = async (addressId) => {
    try {
      await fetchWrapper.delete(`clients/users/addresses/${addressId}`);
      addresses.value = addresses.value.filter(
        (address) => address.id !== addressId
      );
      if ($U.currentUser.mainAddressId === addressId) {
        if (addresses.value.length !== 0) {
          const newId = addresses.value[0].id;
          await updateMainAddressId(newId);
        } else {
          await updateMainAddressId(null);
        }
      }
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Hubo un error al eliminar la direccion", 0);
    }
  };

  const updateMainAddressId = async (addressId) => {
    try {
      const res = await fetchWrapper.put(`clients/users/${$U.currentUser.id}`, {
        mainAddressId: addressId,
      });
      $U.currentUser = res.data.updatedUserClient;
    } catch (e) {
      console.log(e);
    }
  };

  const getOcaBranchs = async (clientPostalCode) => {
    const res = await fetchWrapper.post("oca/getSucursales", {
      "codigoPostal": clientPostalCode,
      "isClient": true,
      "isMerchant": false
    });
    return res.data;
  };

  const geOcaBranchById = async (postalCode, branchId) => {
    try {
      const res = await fetchWrapper.post("oca/getSucursalByBranchId", {
        codigoPostal: postalCode,
        branchId: branchId,
        isClient: true,
        isMerchant: false
      });
      console.log(res.data);
      return res.data;
    } catch(e) {
      console.log(e);
      return false;
    }
  };

  return {
    branches,
    branchId,
    addresses,
    getAllAddresses,
    getAllBranches,
    getAddress,
    newAddress,
    updateAddresses,
    deleteAddress,
    updateMainAddressId,
    getOcaBranchs,
    geOcaBranchById
  };
}, {
  persist: true,
});
