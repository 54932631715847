import { defineStore } from "pinia";
import { ref } from "vue";

export const useSearchStore = defineStore("search", () => {
  const searchs = ref([]);
  const postSearch = (search) => {
    if(searchs.value.length === 8) searchs.value.pop();
    if(!searchs.value.includes(search)) searchs.value.unshift(search);
  };

  const deleteSearch = (index) => {
    searchs.value.splice(index, 1);
  };

  return {searchs, postSearch, deleteSearch};
}, {
  persist: true
});