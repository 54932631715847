<script setup>
/* eslint-disable */
//vue
import {ref, computed } from "vue";
//vue-router
import { RouterLink, useRouter } from "vue-router";
const router = useRouter();
//stores
import { useUserStore, useFavoriteStore, useWindowStore, useSaveStore, useCapacitorStore } from "@/store/stores";
//helpers
import { capitalize } from "@/helpers/capitalize";
//swiper
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
const modules = [Pagination];

const props = defineProps(["data", "ig", "full"]);

const $W = useWindowStore();
const $U = useUserStore();
const $F = useFavoriteStore();
const $S = useSaveStore();
const $C = useCapacitorStore();

const logged = computed(() => Boolean($U.currentUser));

//save
const activeSave = computed(() => $S.save.find(s => s === props.data?.id));

const save = () => {
  if($U.currentUser) {
    if(activeSave.value) {
      $S.deleteSave(props.data?.id);
    } else {
      $S.addSave(props.data?.id);
    }
  } else {
    router.push("/logToContinue");
  }
};

//favorites
const active = ref(props.data?.isFavorited);
const likes = ref(props.data?.favoritedCount);
const like = ref(false)
const tap = ref(0)

const fav = async () => {
  if($U.currentUser) {
    if (active.value) {
      await $F.deleteFavorite(props.data?.id);
      likes.value--;
      active.value = false;
    } else {
      await $F.addFavorite(props.data?.id);
      likes.value++;
      active.value = true;
    }
  } else {
    router.push("/logToContinue");
  }
};

const handleDblTouch = async () => {
  if(tap.value < 2) tap.value++;
  if(tap.value === 2 && $U.currentUser) {
    tap.value++
    like.value = true
    if (!active.value && $U.currentUser) {
      await $F.addFavorite(props.data?.id);
      likes.value++;
      active.value = true;
    }
    setTimeout(() => {
      tap.value = 0
      like.value = false
    }, 600);
  }
  setTimeout(() => {
    if(tap.value === 1) {
      router.push(`/producto/${props.data.id}`)
    }
    tap.value = 0;
  }, 500);
}



//promotions
const getIMGPath = (img) => {
  return img ? require("@/assets/images/prom" + img + ".png") : "";
}

//description
const installmentsAbsorption = ref(props.data?.userMerchant.configuration?.installmentsAbsorption);
const shippingPercentageDiscount = ref(props.data?.userMerchant.configuration?.shippingPercentageDiscount);
const decodeHTMLEntities = (text) => {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
}
const isExpanded = ref(false);
const maxLength = ref($W.windowWidth > 768 ? 140 : 100)
const button = ref(decodeHTMLEntities(props.data?.description).length < maxLength.value)

//image
const imgError = ref({});
const handleError = (id) => {
  imgError.value[id] = true;
};
</script>

<template>

  <div v-if="ig || full" class="fullCardcomponent">

    <div v-if="full" class="card-merchant">
      <RouterLink :to="`/merchant/${data.userMerchant.id}`" class="to-merchant">
        <div v-if="data.userMerchant.image" class="card-merchant__image">
          <img :src="data.userMerchant.image">
        </div>
        <div v-else class="card-merchant__image notfound">
          <v-icon>mdi-image-off-outline</v-icon>
        </div>
        <h1>{{ data.userMerchant.name }}</h1>
      </RouterLink>
    </div>

    <div class="card-image" @click="handleDblTouch" v-if="data?.images && data?.images.length > 0">
      <v-icon v-if="like" class="like">mdi-heart</v-icon>
      <swiper
        :pagination="{
          clickable: true,
        }"
        :loop="true"
        :modules="modules"
      >
        <swiper-slide v-for="(image, index) in data?.images" :key="index">
          <div class="img-index">
            <img :src="imgError[image] || !image ? 'https://camarasal.com/wp-content/uploads/2020/08/default-image-5-1.jpg' : image" @error="handleError(image)" />
          </div>
          <img class="img-background" :src="image" />
        </swiper-slide>
      </swiper>
      <div class="promotions">
        <img v-for="(prom, index) in data?.promotions" :key="index" :src="getIMGPath(prom.type)">
      </div>
    </div>

    <div v-else class="img-notFound"><v-icon>mdi-image-off-outline</v-icon></div>

    <div class="card-info">
      <div class="card-info__name">
        <div>
          <div class="card-likes">
            <p v-if="likes === 1 && active">Te gusta a tí</p>
            <p v-else-if="likes === 2 && active">Te gusta a tí y a otra personas más</p>
            <p v-else-if="likes > 3 && active">Te gusta a tí y a <strong>{{ likes - 1 }} personas más</strong></p>
            <p v-else-if="likes === 1">Le gusta a una persona</p>
            <p v-else-if="likes > 1">Le gusta a <strong>{{ likes }} personas</strong></p>
          </div>
          <RouterLink :to="`/producto/${data.id}`"><h1>{{ capitalize(data?.name) }}</h1></RouterLink>
        </div>
        <div class="card-buttons">
          <v-icon :class="{ active: active }" @click.stop="fav">{{ active ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
          <img :class="{ activeSave: activeSave }" @click.stop="save" src="../../assets/logos/mall-small.svg">
          <v-icon @click="$C.share(`https://mall.tienda/producto/${data?.id}`)">{{ 'mdi-share' }}</v-icon>
        </div>
      </div>
      <div @click="isExpanded = !isExpanded" class="card-info__container">
        <p :class="{text: !isExpanded}">{{ decodeHTMLEntities(data?.description) }}</p>
        <span v-if="!button" class="show">{{ isExpanded ? "Ver menos" : "Ver mas" }}</span>
        <div class="price">
          <strong>${{ data?.price }}</strong>
          <span class="installment" v-if="installmentsAbsorption !== '0'">Mismo precio en {{ installmentsAbsorption }} cuotas de ${{ (data?.price / installmentsAbsorption).toFixed() }}</span>
          <span class="shipping" v-if="shippingPercentageDiscount === 50">Envío a mitad de precio</span>
          <span class="shipping" v-if="shippingPercentageDiscount === 100">Envío gratis</span>
        </div>
      </div>
    </div>
  
  </div>

  <div v-else class="cardcomponent">
    <RouterLink :to="`/producto/${data.id}`" class="card">
  
      <div v-if="data?.images && data?.images.length > 0" class="card-image">
        <div class="index">
          <img :src="data?.images[0]" :alt="data?.name" />
        </div>
        <img class="background" :src="data?.images[0]" :alt="data?.name" />
      </div>

      <div v-else class="img-notFound"><v-icon>mdi-image-off-outline</v-icon></div>
  
      <div class="card-info">
        <p class="card-info__name">{{ capitalize(data?.name) }}</p>
        <p class="card-info__price">${{ data?.price }}</p>
      </div>
  
      <div v-if="$W.windowWidth > 768" class="card-likes">
        <p v-if="likes === 1 && active">Te gusta a tí</p>
        <p v-else-if="likes === 2 && active">Te gusta a tí y a otra personas más</p>
        <p v-else-if="likes > 3 && active">Te gusta a tí y a <strong>{{ likes - 1 }} personas más</strong></p>
        <p v-else-if="likes === 1">Le gusta a una persona</p>
        <p v-else-if="likes > 1">Le gusta a <strong>{{ likes }} personas</strong></p>
      </div>
  
    </RouterLink>
    <v-icon v-if="logged && $W.windowWidth >= 768" :class="{ active: active, hoverActive: !active }" @click.stop="fav">{{ active ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
    <v-icon v-if="logged && $W.windowWidth < 768" :class="{ active: active }" @click.stop="fav">{{ active ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
  </div>
</template>
