import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useFilterStore = defineStore("filter", () => {
  const filterModal = ref(false);

  const filter = ref({
    checkSize: false,
    order: null,
    priceRange: [0, 300000]
  });

  const filterApplied = computed(() => {
    return !(filter.value.checkSize === false && filter.value.order === null && filter.value.priceRange[0] === 0 && filter.value.priceRange[1] === 300000);
  });

  const openModal = () => {
    filterModal.value = true;
  };

  const cleanFilter = () => {
    filter.value = {
      checkSize: false,
      order: null,
      priceRange: [0, 300000]
    };
  };

  return { filterModal, filter, filterApplied, openModal, cleanFilter };
}, {
  persist: true
});