<script setup>
/* eslint-disable */
//vue
import { ref, onMounted } from "vue";
//vue-router
import { useRoute } from "vue-router";
//store
import { useProductStore } from "@/store/stores";
//components
import ProfileCompletation from "@/components/shared/ProfileCompletation.vue";
import InfiniteComponent from "@/components/shared/InfiniteComponent.vue";

const route = useRoute();
const $P = useProductStore();

const products = ref(null);
const count = ref(0);

const loadMore = async () => {
  if(products.value.length < count.value) {
    const res = await $P.getCategoryProducts(route.params.id, products.value.length);
    products.value.push(...res.articles);
    return true;
  } else {
    return false;
  }
};

const filter = async (data) => {
  if(data) {
    products.value = null;
    const res = await $P.getCategoryProducts(route.params.id, 0);
    count.value = res.count;
    products.value = res.articles;
  }
};

onMounted(async () => {
  const res = await $P.getCategoryProducts(route.params.id, 0);
  count.value = res.count;
  products.value = res.articles;
});
</script>

<template>
  <div class="searchview">
    <div class="search-container">
      <InfiniteComponent :products="products" :function="loadMore" :full="true" />
      <ProfileCompletation v-if="products?.length > 0" @apply-filter="filter" />
    </div>
  </div>
</template>