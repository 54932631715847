<script setup>
/* eslint-disable */
import { ref } from "vue";
import { usePaymentStore } from "@/store/payment";

const props = defineProps(["data", "delete"]);

const { deleteCard } = usePaymentStore();

const dltModal = ref(false);
const dltCard = async (index) => {
  await deleteCard(index);
  dltModal.value = false;
};
</script>

<template>
  <div :class="data?.company" class="paymentCard">

    <div class="card-top">
      <p v-if="data?.isDebitCard">Débito</p>
      <p v-else>Crédito</p>
      <v-icon class="delete-icon" v-if="delete" @click="dltModal = true">mdi-trash-can-outline</v-icon>
      <v-icon v-else>mdi-check-decagram-outline </v-icon>
      <v-dialog v-model="dltModal">
        <div class="delete-user">
          <img src="../../assets/logos/mall.svg" >
          <p>¿Quieres eliminar la tarjeta de {{data?.holderName}}?</p>
          <div class="btns">
            <span @click="dltModal = false" class="form-button">Cancelar</span>
            <span @click="dltCard(data.id)" class="blue-button">Eliminar</span>
          </div>
        </div>
      </v-dialog>
    </div>

    <p v-if="data?.company === 'Amex'" class="card-number">**** ****** *{{ data?.lastFourDigits }}</p>
    <p v-else class="card-number">**** **** **** {{ data?.lastFourDigits }}</p>

    <div class="card-info">
      <div>
        <p>Vence el {{ data?.expirationMonth }}/{{ data?.expirationYear }}</p>
        <p>{{ data?.holderName.toUpperCase() }}</p>
      </div>
      <div>
        <img v-show="data?.company === 'Visa'" src="../../assets/logos/visa-w.png" alt="visa">
        <img v-show="data?.company === 'Master'" src="../../assets/logos/master-w.png" alt="master">
        <img v-show="data?.company === 'Amex'" src="../../assets/logos/amex-w.png" alt="amex">
      </div>
    </div>

  </div>
</template>