<script setup>
/* eslint-disable */
//vue
import { computed, onMounted, watch } from "vue";
//vue-router
import { useRouter, useRoute } from "vue-router";
//components
import AddressDetails from "@/components/profile/address/AddressDetails.vue";
//stores
import { useAddressStore, useUserStore } from "@/store/stores";
//helpers
import { capitalize } from "@/helpers/capitalize";

const $U = useUserStore();
const $A = useAddressStore();
const router = useRouter();
const route = useRoute();
const addresses = computed(() => $A.addresses);
onMounted(async () => {
  await $A.getAllAddresses();
});

const chooseAddress = async (addressId) => {
  $U.currentUser.mainAddressId = addressId;
  await $A.updateMainAddressId(addressId);
  router.push(`/cart/purchase/shipping-options`);
};
const newAddress = () => {
  router.push(`/cart/purchase/add/address`);
};
watch(addresses, () => {
  if(addresses.value.length === 0) router.push(`/cart/purchase/shipping-options`);
});
</script>

<template>
  <div class="select-address">

    <div v-if="route.path.split('/').pop() === 'address'" class="addresses">
      <AddressDetails v-for="(address, index) in addresses" :key="index" :data="address" :index="address.id" @click="chooseAddress(address.id)" />
    </div>

    <div v-else class="addresses">
      <div class="addressDetails" v-for="(address, index) in $A.branches" :key="index" @click="$A.branchId = index; router.push(`/cart/purchase/shipping-options`)">
        <v-icon class="icon">mdi-store-outline</v-icon>
        <p class="title">{{ capitalize(address?.Calle) }} {{ address?.Numero }}</p>
        <div class="details">
          <p>Horarios: {{ capitalize(address?.HorarioAtencion) }} / Telefono: {{ address?.Telefono }}</p>
        </div>
      </div>
    </div>

    <div class="buttons">
      <button @click="router.push(`/cart/purchase/shipping-options`)" class="blue-button">Atras</button>
      <button v-if="route.path.split('/').pop() === 'address'" @click="newAddress" class="blue-button">Nuevo Domicilio</button>
    </div>

  </div>
</template>

<style scoped>
.buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
</style>