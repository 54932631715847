<script setup>
/* eslint-disable */
//vue
import { ref, computed, reactive } from "vue";
//vue-router
import { RouterLink } from "vue-router";
//stores
import { useProfileStore, useUserStore, useFilterStore } from "@/store/stores";
const $U = useUserStore();
const $P = useProfileStore();
const $F = useFilterStore();
const profile = $P.currentProfile;
//components
import TorsoModal from "./TorsoModal.vue";
import UpdateShoeData from "./UpdateShoeData.vue";
import PantsModal from "./PantsModal.vue";
//helpers
import { generateInitials, getInitialsClass } from "@/helpers/initials";
//emit
const emit = defineEmits(["apply-filter"]);

const alertModal = ref(false);
const closing = ref(false);
const filterData = reactive({
  checkSize: $F.filter.checkSize,
  order: $F.filter.order,
  priceRange: [$F.filter.priceRange[0], $F.filter.priceRange[1]]
});
const profileSizes = {
  torso:  $P.currentProfile.torso,
  bottomSize:  $P.currentProfile.bottomSize,
  shoeSize:  $P.currentProfile.shoeSize,
}

const filterModified = computed(() => {
  return filterData.checkSize === $F.filter.checkSize &&
  filterData.order === $F.filter.order &&
  filterData.priceRange[0] === $F.filter.priceRange[0] &&
  filterData.priceRange[1] === $F.filter.priceRange[1] &&
  $P.currentProfile.torso === profileSizes.torso &&
  $P.currentProfile.bottomSize === profileSizes.bottomSize &&
  $P.currentProfile.shoeSize === profileSizes.shoeSize;
});

const order = (type) => {
  if(type === "asc") {
    if(filterData.order === null || filterData.order === "DESC") filterData.order = "ASC"
    else filterData.order = null
  }
  if(type === "desc") {
    if(filterData.order === null || filterData.order === "ASC") filterData.order = "DESC"
    else filterData.order = null
  }
}

const applyFilter = (reset) => {
  if(reset) {
    filterData.checkSize = false;
    filterData.order = null;
    filterData.priceRange = [0, 300000];
    $F.cleanFilter();
  }
  else {
    $F.filter.checkSize = filterData.checkSize
    $F.filter.order = filterData.order
    $F.filter.priceRange = filterData.priceRange
  }
  $F.filterModal = false;
  emit("apply-filter", true);
}

const closeModal = () => {
  closing.value = true;
  setTimeout(() => {
    closing.value = false;
    $F.filterModal = false;
  }, 250);
};

</script>

<template>
  <div @click="$F.filterModal = true" class="modalButton" :class="{applied: $F.filterApplied}">
    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M4 6l8 0" /><path d="M16 6l4 0" /><path d="M8 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M4 12l2 0" /><path d="M10 12l10 0" /><path d="M17 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M4 18l11 0" /><path d="M19 18l1 0" /></svg>
  </div>

  <div v-if="$F.filterModal" @click="closeModal" class="profileBackground" :class="{closeAnimation: closing}"></div>

  <div v-if="$F.filterModal" class="profile-completation" :class="{closeAnimation: closing}">

    <v-icon @click="closeModal" class="close">mdi-close</v-icon>
    
    <RouterLink :to=" $U.currentUser ? `/profile/info/my-profiles` : '/signin'" class="profile-name">
      <div class="profile-img" :class=getInitialsClass(profile.alias)>
        <img v-if="profile.image" :src="profile.image" />
        <span v-else>{{ generateInitials(profile.alias) }}</span>
      </div>
      <span>{{ profile.alias?.toUpperCase() }}</span>
    </RouterLink>

    <div class="profile-sizes">
      <div class="profile-images" @click="$P.openUpdateShoeData = true" :class="{active: profile.shoeSize}">
        <img src="../../assets/shoe-blue.svg"/>
        <UpdateShoeData />
        <div class="floating-size" v-if="profile.shoeSize">
          {{ profile.shoeSize }}
        </div>
      </div>
      <div class="profile-images" @click="$P.openTorsoModal = true" :class="{active: profile.torso}">
        <img src="../../assets/blue-t-shirt.svg"/>
        <TorsoModal />
        <div class="floating-size" v-if="profile.torso">
          {{ profile.torso }}
        </div>
      </div>
      <div class="profile-images" @click="$P.openPantsModal = true" :class="{active: profile.bottomSize}">
        <img src="../../assets/blue-t-pant.svg"/>
        <PantsModal />
        <div class="floating-size" v-if="profile.bottomSize">
          {{ profile.bottomSize }}
        </div>
      </div>
    </div>

    <div class="filter-container">
      <div class="header">
        <span>Filtros de busqueda</span>
      </div>
      <div class="filters">
        <div class="checkSize">
          <v-switch
            v-model="filterData.checkSize"
            :label="'Aplicar talles seleccionados'"
            :ripple="false"
            :class="{active: filterData.checkSize}"
            hide-details
          ></v-switch>
          <div @click="alertModal = !alertModal" class="icon">
            <v-icon>mdi-alert-circle-outline</v-icon>
          </div>
          <div v-if="alertModal" @click="alertModal = false" class="alert">
            <v-icon>mdi-close</v-icon>
            <p>Al activar esta opción se te mostrarán únicamente productos que tengan los talles indicados en el perfil</p>
          </div>
        </div>
        <div class="price">
          <span>Precios</span>
          <div class="asc-des">
            <div class="check" @click="order('asc')">
              <span>Menor precio</span>
              <div class="checkinput" :class="{active: filterData.order === 'ASC'}">
                <v-icon class="active" v-if="filterData.order === 'ASC'">mdi-minus-circle</v-icon>
                <v-icon v-else>mdi-circle-outline</v-icon>
              </div>
            </div>
            <div class="check" @click="order('desc')">
              <span>Mayor precio</span>
              <div class="checkinput" :class="{active: filterData.order === 'DESC'}">
                <v-icon class="active" v-if="filterData.order === 'DESC'">mdi-plus-circle</v-icon>
                <v-icon v-else>mdi-circle-outline</v-icon>
              </div>
            </div>
          </div>
          <div class="slider">
            <v-range-slider
              v-model="filterData.priceRange"
              :max="300000"
              :min="0"
              :step="10000"
              :ripple="false"
              hide-details
            >
            </v-range-slider>
            <div class="inputs">
              <input type="number" v-model="filterData.priceRange[0]">
              <input type="number" v-model="filterData.priceRange[1]">
            </div>
          </div>
        </div>
        <span class="reset" @click="applyFilter(true)">Eliminar filtros</span>
        <button :disabled="filterModified" @click="applyFilter(false)" class="blue-button">Aplicar</button>
      </div>
    </div>

  </div>
</template>
