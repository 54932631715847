import { defineStore } from "pinia";
import { usePopStore } from "@/store/stores";
import { fetchWrapper } from "@/helpers/fetchWrapper";

export const useFavoriteStore = defineStore(
  "favorite",
  () => {
    const $P = usePopStore();

    const getFavorites = async (count) => {
      try {
        const res = await fetchWrapper.get(`favorites/userClient?limit=20&offset=${count}`);
        const data = res.data.favorites.map(x => ({
          ...x.article,
          userMerchant: x.userMerchant,
          isFavorited: true
        }));
        console.log(data);
        return {
          article: data,
          count: res.data.count
        };
      } catch (e) {
        console.log(e);
        $P.popUpHandler("Hubo un error cargando los favoritos", 0);
      }
    };

    const addFavorite = async (articleId) => {
      try {
        await fetchWrapper.post("favorites", {articleId});
        return true;
      } catch (e) {
        console.log(e);
        $P.popUpHandler("Hubo un error al agregar a favoritos", 0);
        return false;
      }
    };

    const deleteFavorite = async (articleId) => {
      try {
        await fetchWrapper.delete(`favorites/byArticle/${articleId}`);
        return true;
      } catch (e) {
        console.log(e);
        $P.popUpHandler("Hubo un error al eliminar de favoritos", 0);
        return false;
      }
    };

    return {
      getFavorites,
      addFavorite,
      deleteFavorite,
    };
  },
  {
    persist: true,
  }
);
