<template>
  <div class="payment-methods">
    <p class="title">Medios de pago MALL</p>
    <div class="method">
      <p class="subtitle">Tarjetas de débito</p>
      <div class="banks">
        <img src="../../assets/logos/maestro.png">
        <img src="../../assets/logos/mastercard.png">
        <img src="../../assets/logos/cabal.png">
        <img src="../../assets/logos/visa.png">
      </div>
    </div>
    <div class="method">
      <p class="subtitle">Tarjetas de crédito</p>
      <div class="banks">
        <img src="../../assets/logos/american.png">
        <img src="../../assets/logos/mastercard.png">
        <img src="../../assets/logos/cabal.png">
        <img src="../../assets/logos/visa.png">
        <img src="../../assets/logos/naranja.png">
      </div>
    </div>
    <span>La cantidad de cuotas puede variar según la tarjeta</span>
  </div>
</template>