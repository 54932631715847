<script setup>
/* eslint-disable */
//vue
import { ref, onMounted } from "vue";
//vue-router
import { useRoute, useRouter } from "vue-router";
//components
import bannerCarrousel from "@/components/shared/bannerCarrousel.vue";
import CategoriesCarrousel from "@/components/shared/CategoriesCarrousel.vue";
import InfiniteComponent from "@/components/shared/InfiniteComponent.vue";
import ProfileCompletation from "@/components/shared/ProfileCompletation.vue";
//store
import { useProductStore, useBannerStore, useSocialStore, useCapacitorStore, useUserStore } from "@/store/stores";

const route = useRoute();
const router = useRouter();
const $P = useProductStore();
const $B = useBannerStore();
const $S = useSocialStore();
const $C = useCapacitorStore();
const $U = useUserStore();

const loaded = ref(false);
const merchant = ref({});
const banners = ref([]);
const categories = ref([]);
const products = ref(null);
const count = ref();
const merchantData = ref(null);
const isFollowed = ref(null);

const loadMore = async () => {
  if(products.value.length < count.value) {
    const res = await $P.getByMerchant(route.params.merchant, null, products.value.length);
    products.value.push(...res.articles);
    return true
  } else {
    return false;
  }
};

onMounted(async () => {
  const promises = [];
  promises.push(
    $B.getBannersByMerchant(route.params.merchant),
    $P.getCategoriesByMerchant(route.params.merchant),
    $P.getMerchant(route.params.merchant),
    $S.getMerchantData(route.params.merchant),
    $P.getByMerchant(route.params.merchant, null, 0)
  );
  const promisesResult = await Promise.all(promises);
  banners.value = promisesResult[0];
  categories.value = promisesResult[1];
  merchant.value = promisesResult[2];
  merchantData.value = [
    {
      name: "Seguidores",
      stat: promisesResult[3].followers
    },
    {
      name: "Productos",
      stat: promisesResult[3].availableArticlesCount
    }
  ];
  isFollowed.value = promisesResult[3].isFollowed;
  products.value = promisesResult[4].articles;
  count.value = promisesResult[4].count;
  loaded.value = true
});

const filter = async (data) => {
  if(data) {
    products.value = null;
    const res = await $P.getByMerchant(route.params.merchant, null, 0);
    products.value = res.articles;
    count.value = res.count;
  }
}

const follow = async () => {
  if(!$U.currentUser) {
    router.push("/logToContinue");
    return;
  }
  if(!isFollowed.value) {
    const res = await $S.followMerchant(route.params.merchant);
    if(res) {
      isFollowed.value = !isFollowed.value
      merchantData.value[0].stat += 1;
    }
  } else {
    const res = await $S.unfollowMerchant(route.params.merchant);
    if(res) {
      isFollowed.value = !isFollowed.value
      merchantData.value[0].stat -= 1;
    }
  }
};

</script>

<template>
  <div class="loading" v-if="!loaded">
    <img class="manijita" src="../assets/logos/manijita.svg">
    <img class="bolsa" src="../assets/logos/bolsa.svg">
  </div>
  <div v-else class="merchant-container">

    <div v-if="banners.length > 0" class="merchant-banner">
      <bannerCarrousel :data="banners?.map(img => img.image)" />
    </div>

    <div class="merchant-info">
      <div class="merchant-logo">
        <img :src="merchant.image">
      </div>

      <div class="merchant-name">
        <h1>{{ merchant?.name }}</h1>
        <v-icon>mdi-check-decagram</v-icon>
      </div>

      <div class="merchant-stats">
        <div class="data" v-for="(x, index) in merchantData" :key="index">
          <h1>{{ x.stat }}</h1>
          <p>{{ x.name }}</p>
        </div>
      </div>

      <div class="merchant-description">
        {{ merchant?.description }}
      </div>
      
      <div class="buttons">
        <button @click="follow" class="follow-button" :class="{isFollowed: isFollowed}">{{ isFollowed ? "Dejar de seguir" : "Seguir" }}</button>
        <button @click="$C.share(`https://mall.tienda/merchant/${route.params.merchant}`)" class="share-button"><v-icon>mdi-share</v-icon></button>
      </div>
    </div>

    <CategoriesCarrousel :data="categories" />

    <InfiniteComponent :products="products" :function="loadMore" :ig="true" />

    <ProfileCompletation @apply-filter="filter" />

  </div>
</template>