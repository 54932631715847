<script setup>
/* eslint-disable */
//vue
import { ref, computed, onMounted } from "vue";
//vue-router
import { RouterLink, useRouter } from "vue-router";
//stores
import { useUserStore, usePurchaseStore, useAddressStore, useWindowStore, useCouponStore } from "@/store/stores";
//components
import CouponComponent from "@/components/shared/CouponComponent.vue";
//helpers
import { capitalize } from "@/helpers/capitalize";

const router = useRouter();
const $U = useUserStore();
const $P = usePurchaseStore();
const $A = useAddressStore();
const $W = useWindowStore();
const $C = useCouponStore();

const userAddress = computed(() => {
  return $U.currentUser.address;
});
const branchAddresses = ref([]);
const userAddresses = ref([]);
const firstMerchant = $P.cart.merchants[Object.keys($P.cart.merchants)[0]].merchant.address

const loading = ref(true);
const modalWarning = ref(false);
const modalCoupon = ref(false);
const textCoupon = ref("");
const couponData = ref(null);
const validateCoupon = async () => {
  if(textCoupon.value !== "") {
    const res = await $C.validateCoupon(textCoupon.value);
    if(res) couponData.value = res;
    textCoupon.value = "";
  }
};
const useCoupon = async () => {
  const res = await $C.useCoupon($P.cart.merchants, couponData.value);
  if(res) {
    modalCoupon.value = false;
    couponData.value = null;
    textCoupon.value = "";
  }
};
const convertToUppercase = () => {
  textCoupon.value = textCoupon.value.toUpperCase();
};

const shippingToHome = ref({
  details: [],
  total: 0
})

const shippingToBranch = ref({
  details: [],
  total: 0
})

const details = (key, shipping, cost) => {
  const details = {
    name: $P.cart.merchants[key].merchant.name,
    data: cost,
    shippingDiscount: $P.cart.merchants[key].merchant.configuration.shippingPercentageDiscount
  };
  shipping.details.push(details);
};

const total = (shipping) => {
  const map = shipping.details.map(x => Math.ceil(x.data));
  shipping.total = map.reduce((a, b) => a + b, 0);
}

onMounted(async () => {
  if($P.cart.totalPrice === 0) {
    router.push("/");
    return;
  }
  $P.step = 1;
  $P.shipping.shippingPrice = 0;
  console.log("userAddress", userAddress.value);
  if(userAddress?.value) {
    branchAddresses.value = await $A.getAllBranches(userAddress?.value?.postalCode);
    const operativas = await $P.getOpetativas();
    const promiseList = [];
    for (const key in $P.cart.merchants) {
      const merchantConfig = $P.cart.merchants[key].merchant.configuration.shippingPercentageDiscount;
      const data = $P.cart.merchants[key].products.map(p => {
        return {
          articleVariantId: p.variantId,
          quantity: p.quantity,
        }
      });
  
      if(userAddress?.value?.postalCode) {
        const puerta = $P.getShippingCost(operativas.puerta, userAddress?.value?.postalCode, data, merchantConfig).then((costToHome) => details(key, shippingToHome.value, costToHome));
        promiseList.push(puerta);
      }
  
      const sucursal = $P.getShippingCost(operativas.sucural, userAddress?.value?.postalCode, data, merchantConfig).then((costToBranch) => details(key, shippingToBranch.value, costToBranch));
      promiseList.push(sucursal)
    }
      
    Promise.all(promiseList).then(() => {
      console.log("shippingToHome", shippingToHome.value);
      console.log("shippingToBranch", shippingToBranch.value);
      total(shippingToHome.value);
      total(shippingToBranch.value);
    })
  }
  loading.value = false;
});

const radios = ref(null);

const chooseShipping = (option) => {
  if(option === "one" && radios.value !== "one") {
    if(userAddress.value?.postalCode) {
      radios.value = "one";
      modalWarning.value = false;
    }
  }
  if(option === "two" && radios.value !== "two") {
    if(userAddress.value?.postalCode) {
      radios.value = "two";
      modalWarning.value = false;
    }
  }
  if(option === "three" && radios.value !== "three") {
    radios.value = "three";
    modalWarning.value = true;
  }
}

const shipping = async () => {
  if (radios.value === "one") {
    $P.shipping.address = userAddress?.value;
    $P.shipping.shippingPrice = shippingToHome.value.total;
    $P.shipping.method = "home";
  } else if (radios.value === "two") {
    $P.shipping.address = branchAddresses.value[$A.branchId];
    $P.shipping.shippingPrice = shippingToBranch.value.total;
    $P.shipping.method = "branch";
  } else {
    $P.shipping.address = $P.cart.merchants[Object.keys($P.cart.merchants)[0]].merchant.address;
    $P.shipping.shippingPrice = 0;
    $P.shipping.method = "pickUp";
  }
  await $P.generatePreOrders();
  $P.step = 2;
  router.push(`/cart/purchase/payment-options`);
};
</script>

<template>
  <div class="loading" v-if="loading">
    <img class="manijita" src="../../assets/logos/manijita.svg">
    <img class="bolsa" src="../../assets/logos/bolsa.svg">
  </div>
  <div v-else class="purchase-shipping">
    <p class="purchase-title">¿Cómo querés recibir o retirar tu compra?</p>
    <div class="options-shipping">
        <div v-if="branchAddresses" @click="chooseShipping('one')" :class="{'borderSelected': radios === 'one'}" class="options-shipping__option">
          <input v-if="userAddress?.postalCode" type="radio" v-model="radios" value="one" />
          <v-icon v-if="$W.windowWidth > 768">mdi-home-variant-outline</v-icon>
          <h2>Enviar a domicilio</h2>
          <div v-if="userAddress?.postalCode" class="shipping-info">
            <p>{{ capitalize(userAddress?.mainStreet) + " " + userAddress?.number }}</p>
            <p>{{ "C.P. " + userAddress?.postalCode + " - " + capitalize(userAddress?.location) + ", " + capitalize(userAddress?.state) }}</p>
            <p>{{ userAddress?.alias + " - " + userAddress?.phone }}</p>
          </div>

          <div v-else class="shipping-info">
            <p>No tenes un domicilio agendado</p>
          </div>

          <div v-if="userAddress?.postalCode" class="shipping-price">
            {{ shippingToHome.total === 0 ? 'GRATIS' : `$${shippingToHome.total}` }}
          </div>
          <RouterLink v-if="userAddress?.postalCode" class="primary-button" :to="`/cart/purchase/select/address`">Editar o elegir otro domicilio</RouterLink>
          <RouterLink v-else class="primary-button" :to="`/cart/purchase/add/address`">Agregar domicilio</RouterLink>
          <RouterLink v-if="userAddresses?.length > 0" class="primary-button" :to="`/cart/purchase/select/address`">Elegir un domicilio</RouterLink>
        </div>

        <div v-else class="options-shipping__option">
          <v-icon v-if="$W.windowWidth > 768">mdi-home-variant-outline</v-icon>
          <h2>Enviar a domicilio</h2>
          <div v-if="userAddress?.postalCode" class="shipping-info">
            <p>{{ capitalize(userAddress?.mainStreet) + " " + userAddress?.number }}</p>
            <p>{{ "C.P. " + userAddress?.postalCode + " - " + capitalize(userAddress?.location) + ", " + capitalize(userAddress?.state) }}</p>
            <p>{{ userAddress?.alias + " - " + userAddress?.phone }}</p>
          </div>

          <RouterLink v-if="userAddress?.postalCode" class="primary-button" :to="`/cart/purchase/select/address`">Editar o elegir otro domicilio</RouterLink>
          <RouterLink v-else class="primary-button" :to="`/cart/purchase/add/address`">Agregar domicilio</RouterLink>
          <RouterLink v-if="userAddresses?.length > 0" class="primary-button" :to="`/cart/purchase/select/address`">Elegir un domicilio</RouterLink>
        </div>
        <div v-if="branchAddresses.length" @click="chooseShipping('two')" :class="{'borderSelected': radios === 'two'}" class="options-shipping__option">
          <input v-if="userAddress?.postalCode" type="radio" v-model="radios" value="two" />
          <div v-if="$W.windowWidth > 768" class="icon">
            <img src="../../assets/logos/oca.png">
          </div>
          <h2>Retirar en sucursal de OCA</h2>
          <div v-if="userAddress?.postalCode" class="shipping-info">
            <p>{{ capitalize(branchAddresses[$A.branchId]?.Calle) }} {{ branchAddresses[$A.branchId]?.Numero }}</p>
            <p>Horarios: {{ capitalize(branchAddresses[$A.branchId]?.HorarioAtencion) }}</p>
            <p>Telefono: {{ branchAddresses[$A.branchId]?.Telefono }}</p>
          </div>
          <div v-if="userAddress?.postalCode" class="shipping-price">
            {{ shippingToBranch.total === 0 ? 'GRATIS' : `$${shippingToBranch.total}` }}
          </div>
          <RouterLink v-if="userAddress?.postalCode" class="primary-button" :to="`/cart/purchase/select/branch`">Seleccionar otra sucursal</RouterLink>
        </div>
        <div v-else class="options-shipping__option">
          <div v-if="$W.windowWidth > 768" class="icon">
            <img src="../../assets/logos/oca.png">
          </div>
          <h2>Retirar en sucursal de OCA</h2>
          <div v-if="!userAddress" class="shipping-info">
            <p>Agrega un domicilio para calcular el código postal y elegir tu sucursal de OCA. 😊</p>
          </div>
          <div v-if="userAddress?.postalCode" class="shipping-info">
            <p>Verificar el codigo postal ingresado en el domicilio.</p>
          </div>
        </div>
    </div>
    <div v-if="Object.keys($P.cart.merchants).length === 1 && $P.cart.merchants[Object.keys($P.cart.merchants)[0]].merchant.allowsPickUpStore" @click="chooseShipping('three')" :class="{'borderSelected': radios === 'three'}" class="branch-option">
      <v-icon v-if="$W.windowWidth > 768">mdi-account-arrow-right-outline</v-icon>
      <strong>Retirar en tienda</strong>
      <div>
        <strong>Dirección: {{ capitalize(firstMerchant.mainStreet) }} {{ firstMerchant.number }}</strong>
        <p>{{ "C.P. " + firstMerchant?.postalCode + " - " + firstMerchant.location + ", " +
          firstMerchant.state }}</p>
        <p>{{ firstMerchant.alias + " - " + firstMerchant.phone }}</p>
      </div>
      <input type="radio" v-model="radios" value="three" />
    </div>
    <div v-else-if="Object.keys($P.cart.merchants).length === 1 && !$P.cart.merchants[Object.keys($P.cart.merchants)[0]].merchant.allowsPickUpStore" class="branch-option">
      <span>Esta tienda no tiene la opcion de retirar por local</span>
    </div>
    <div v-else class="branch-option">
      <span>Para retirar tus compras por tienda tenes que realizar las ordenes por separado</span>
    </div>
    <div class="buttons">
      <button @click="modalCoupon = true" class="blue-button">Agregar cupón</button>
      <button :disabled="!radios" @click="shipping" class="blue-button">Continuar</button>
    </div>

    <v-dialog v-model="modalCoupon">
      <div class="coupon-form" :class="{'coupon-form_correct': couponData}">
        <img src="../../assets/logos/mall.svg" >
        <p>Ingresá el código de tu cupón</p>
        <div class="coupon-input">
          <input v-if="!couponData" type="text" placeholder="Código del cupón" v-model="textCoupon" @input="convertToUppercase">
          <CouponComponent class="transition" v-else :data="couponData" />
        </div>
        <div class="btns">
          <span @click="modalCoupon = false; couponData = null" class="form-button">Cancelar</span>
          <span v-if="!couponData" @click="validateCoupon" class="blue-button">Validar cupón</span>
          <span v-else @click="useCoupon" class="blue-button">Usar cupón</span>
        </div>
      </div>
    </v-dialog>

    <v-dialog v-model="modalWarning">
      <div class="coupon-form" :class="{'coupon-form_correct': couponData}">
        <img src="../../assets/logos/mall.svg" >
        <p class="text-center px-6">Estas seleccionando la opcion de retirar en tienda en la localidad de {{ firstMerchant.location }}</p>
        <div class="btns">
          <span @click="modalWarning = false" class="blue-button">Aceptar</span>
        </div>
      </div>
    </v-dialog>

    <div class="shipping-cost">

      <div v-if="radios === 'one'" class="costs" v-for="(merchant, index) in shippingToHome.details" :key="index">
        <div>
          <p>Envio de {{ merchant.name }} a domicilio.</p>
          <!-- <p v-if="merchant.data.plazoEntrega === 1">Llega dentro de {{ merchant.data.plazoEntrega }} día hábil.</p> -->
          <!-- <p v-else>Llega dentro de {{ merchant.data.plazoEntrega }} días hábiles.</p> -->
        </div>
        <span v-if="merchant.shippingDiscount === 50">Envio a mitad de precio ${{ Math.ceil(merchant.data) }}</span>
        <span v-else-if="merchant.shippingDiscount === 100">GRATIS</span>
        <span v-else>${{ Math.ceil(merchant.data) }}</span>
      </div>

      <div v-if="radios === 'two'" class="costs" v-for="(merchant, index) in shippingToBranch.details" :key="index">
        <div>
          <p>Envio de {{ merchant.name }} a la sucursal de OCA.</p>
          <!-- <p v-if="merchant.data.plazoEntrega === 1">Llega dentro de {{ merchant.data.plazoEntrega }} día hábil.</p> -->
          <!-- <p v-else>Llega dentro de {{ merchant.data.plazoEntrega }} días hábiles.</p> -->
        </div>
        <span v-if="merchant.shippingDiscount === 50">Envio a mitad de precio ${{ Math.ceil(merchant.data) }}</span>
        <span v-else-if="merchant.shippingDiscount === 100">GRATIS</span>
        <span v-else>${{ Math.ceil(merchant.data) }}</span>
      </div>

    </div>
  </div>
</template>

<style scoped>
.borderSelected {
  border: 1px solid #13C296;
}
.blue-button {
  align-self: flex-end;
}
</style>