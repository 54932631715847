<script setup>
//vue
import { ref } from "vue";
//vue-router
import { RouterLink } from "vue-router";
//components
import OptionProfileComponent from "@/components/profile/OptionProfileComponent.vue";
//stores
import { useUserStore, useWindowStore } from "@/store/stores";
//helpers
import { generateInitials, getInitialsClass } from "@/helpers/initials";
import { capitalize } from "@/helpers/capitalize";

const $W = useWindowStore();
const $U = useUserStore();
const user = $U.currentUser;
const error = ref(false);

const setError = () => {
  error.value = true;
};

</script>

<template>
  <div class="profile">
    <div class="profile-info">
      <RouterLink :to="`/profile/${$U.currentUser?.username}`" class="info">
        <div class="profile-info__img">
          <img
            class="image-profile"
            v-if="$U.currentUser.image && !error"
            :src="$U.currentUser.image"
            :alt="user.firstName"
            @error="setError"
          />
          <div v-else :class="getInitialsClass($U.currentUser.firstName)">
            {{ generateInitials($U.currentUser.firstName)
            }}{{ generateInitials($U.currentUser.lastName) }}
          </div>
        </div>
        <div class="profile-info__text">
          <h1>
            {{ capitalize(user.firstName) }} {{ capitalize(user.lastName) }}
          </h1>
          <h3>{{ user.email }}</h3>
          <span>Ver perfil</span>
        </div>
      </RouterLink>

      <div v-if="$W.windowWidth > 768" @click="$U.logout()" class="primary-button">
        Cerrar sesión
      </div>
    </div>

    <div class="profile-options">
      <OptionProfileComponent
        :path="'my-purchases'"
        :icon="'mdi-shopping-outline'"
        :title="'Compras'"
        :description="'Registro de tus compras'"
      />

      <OptionProfileComponent
        :path="'my-profiles'"
        :icon="'mdi-account-group'"
        :title="'Perfiles'"
        :description="'perfiles guardados en tu cuenta'"
      />

      <OptionProfileComponent
        :path="'saved'"
        :icon="'mdi-bookmark-outline'"
        :title="'Guardados'"
        :description="'Productos guardados'"
      />

      <OptionProfileComponent
        :path="'favorites'"
        :icon="'mdi-heart-box-outline'"
        :title="'Favoritos'"
        :description="'Todos tus favoritos'"
      />

      <OptionProfileComponent
        :path="'my-cards'"
        :icon="'mdi-credit-card-outline'"
        :title="'Tarjetas'"
        :description="'Tarjetas guardadas en tu cuenta'"
      />

      <OptionProfileComponent
        :path="'my-addresses'"
        :icon="'mdi-map-marker-outline'"
        :title="'Direcciones'"
        :description="'Direcciones guardadas en tu cuenta'"
      />

      <OptionProfileComponent
        :path="'my-security'"
        :icon="'mdi-lock-outline'"
        :title="'Seguridad'"
        :description="'Configura la seguridad de tu cuenta'"
      />

      <OptionProfileComponent
        :path="'my-notifications'"
        :icon="'mdi-cash'"
        :title="'Notificaciones'"
        :description="'Registro de las Notificaciones'"
      />
    </div>
  </div>
</template>