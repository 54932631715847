<script setup>
/* eslint-disable */
//vue
import { ref, computed, onMounted, onUnmounted } from "vue";
//vue-router
import { useRouter } from "vue-router";
const router = useRouter();
//components
import CardComponent from "@/components/shared/CardComponent.vue";
import CardSkeleton from "@/components/skeletons/CardSkeleton.vue"
//store
import { useWindowStore, useFilterStore } from "@/store/stores";

const props = defineProps(["products", "function", "ig", "full"]);
const $W = useWindowStore();
const $F = useFilterStore();
const inf = ref(null);

const divideProducts = computed(() => {
  const products = props.products;
  const left = [];
  const right = [];
  for (let i = 0; i < products.length; i++) {
    if(i % 2 === 0) {
      left.push(products[i]);
    } else {
      right.push(products[i]);
    }
  }
  return {left, right};
});

const handleScroll = async () => {
  const height = $W.windowWidth > 768 ? 2000 : 5000;
  if(inf.value && (inf.value.getBoundingClientRect().bottom - height < window.innerHeight)) {
    window.removeEventListener("scroll", handleScroll);
    const res = await props.function();
    if(res) {
      window.addEventListener("scroll", handleScroll);
    }
  }
};

onMounted(async () => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<template>

  <div v-if="!products" class="infinite-skeleton">
    <CardSkeleton />
    <CardSkeleton />
  </div>

  <div v-else-if="products.length > 0 && $W.windowWidth < 768" class="infinite-scroll__mobile" ref="inf">
    <CardComponent v-for="(product, index) in products" :data="product" :ig="ig" :full="full" :key="index"/>
  </div>

  <div v-else-if="products.length > 0 && $W.windowWidth >= 768" class="infinite-scroll__desktop" ref="inf">
    <div class="left">
      <CardComponent v-for="(product, index) in divideProducts.left" :data="product" :ig="ig" :full="full" :key="index"/>
    </div>
    <div class="right">
      <CardComponent v-for="(product, index) in divideProducts.right" :data="product" :ig="ig" :full="full" :key="index"/>
    </div>
  </div>

  <div v-if="products?.length > 0 && $F.filterApplied" class="empty-products">
    <div>
      <span>¡Has llegado al final!</span>
      <p>Parece que has explorado todo lo que los filtros permiten ver 🔍✨. Si no encuentras lo que buscas, ajusta los filtros para descubrir más opciones disponibles en la tienda 😉</p>
    </div>
    <button @click="openModal" class="blue-button">Abrir filtros</button>
  </div>

  <div v-else-if="products?.length === 0 && $F.filterApplied" class="empty-products">
    <img src="../../assets/svgs/search_one.svg" alt="svg">
    <div>
      <span>¡Ups, demasiados filtros!</span>
      <p>Ajusta o elimina los filtros para que los productos vuelvan a aparecer y encuentres justo lo que necesitas 😉✨</p>
    </div>
    <button @click="$F.openModal" class="blue-button">Abrir filtros</button>
  </div>

  <div v-else-if="products?.length === 0" class="empty-products">
    <img src="../../assets/svgs/search_one.svg" alt="svg">
    <div>
      <span>¡Ups! Sin productos por ahora 😔</span>
      <p>No hay productos disponibles en este momento, pero no te preocupes, ¡pronto volverán! 🛍️✨ Mantente atento para descubrir las próximas novedades</p>
    </div>
    <button @click="router.push('/')" class="blue-button">Ir al inicio</button>
  </div>

</template>