export const validateCard = (cardNumber) => {
  cardNumber = cardNumber?.replace(/[\s-]/g, "");

  const visa = /^4[0-9]{7}$/;
  const master = /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{4}$/;
  const amex = /^3[47][0-9]{6}$/;

  if(visa.test(cardNumber)) {
    return "Visa";
  } else if (master.test(cardNumber)) {
    return "Master";
  } else if (amex.test(cardNumber)) {
    return "Amex";
  } else {
    return null;
  }
};

export const validarInput = e => {
  const tecla = e.key;
  if ((tecla >= "0" && tecla <= "9") || tecla === " " || tecla === "/") {
    return true;
  } else {
    e.preventDefault();
    return false;
  }
};

export const addSpaces = (input, company) => {
  if(company === "Amex") {
    return input.replace(/^(\d{4})(\d+)/, "$1 $2").replace(/^(\d{4} \d{6})(\d+)/, "$1 $2");
  } else {
    const regex = /\d{4}(?=\d)/g;
    return input.replace(regex, match => match + " ");
  }
};

export const removeSpaces = (input) => {
  return input.replace(/\s+/g, "");
};

export const addSlash = (input) => {
  const regex = /^(\d{2})(.*)$/;
  return input.replace(regex, "$1/$2");
};