<script setup>
//vue
import { onMounted } from "vue";
//stores
import { useCapacitorStore } from "@/store/stores";
const $C = useCapacitorStore();

onMounted(() => {
  if($C.downloadModal) {
    document.body.classList.add("no-scroll");
  }
});

const goToStore = () => {
  if($C.deviceInfo.operatingSystem === "android") {
    window.open("https://play.google.com/store/apps/details?id=com.malltienda.app");
  } else {
    window.open("https://apps.apple.com/us/app/mall/id6497651599");
  }
};

const close = () => {
  $C.downloadModal = false;
  document.body.classList.remove("no-scroll");
};
</script>

<template>
  <div class="download-container">
    <div class="download">
      <div class="logo">
        <svg width="60" height="60" viewBox="0 0 60 67" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M42.0384 54.8383L30.2561 66.5236L0.384205 36.8658L0 13.1459L42.0384 54.8383Z" fill="#fff"/>
        <path d="M35.2185 38.2947L47.0008 49.98L59.6155 37.4056L59.9997 13.6857L35.2185 38.2947Z" fill="#fff"/>
        <path d="M38.709 66.9999H60.0004V45.9156L38.709 66.9999Z" fill="#fff"/>
        <path d="M0 45.9156V66.9999H21.2914L0 45.9156Z" fill="#fff"/>
        <path d="M31.1848 0C23.0844 0 16.4569 6.54123 16.4569 14.6066C16.4569 15.6863 17.3534 16.5754 18.442 16.5754C19.5305 16.5754 20.427 15.6863 20.427 14.6066C20.427 8.73223 25.2616 3.93744 31.1848 3.93744C37.1079 3.93744 41.9425 8.73223 41.9425 14.6066C41.9425 15.6863 42.839 16.5754 43.9276 16.5754C45.0161 16.5754 45.9126 15.6863 45.9126 14.6066C45.8806 6.54123 39.2851 0 31.1848 0Z" fill="#fff"/>
        </svg>
      </div>
      <p>Instalá la app y comprá más fácil y rápido</p>
      <button @click="goToStore" class="blue-button">Instalar Mall Tienda</button>
      <span @click="close">Por ahora no</span>
    </div>
  </div>
</template>