<script setup>
/* eslint-disable */
//vue
import { ref, reactive, onMounted } from "vue";
//stores
import { useUserStore, useInputStore, usePopStore, useWindowStore, useCapacitorStore } from "@/store/stores";
//firebase
import { sendPasswordResetEmail, OAuthProvider, GoogleAuthProvider } from "firebase/auth";
import { signInWithCredential, getAuth } from "firebase/auth/cordova";
//capacitor
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { SignInWithApple } from "@capacitor-community/apple-sign-in";

const $U = useUserStore();
const $P = usePopStore();
const $W = useWindowStore();
const $C = useCapacitorStore();
const { required } = useInputStore();

const showPassw = ref(false);
const modal = ref(false);
const modalEmail = ref("");
const formData = reactive({
  email: "",
  password: ""
});

const loginUser = async () => {
  await $U.login(formData.email.toLowerCase(), formData.password);
};

const resetPasswd = async (email) => {
  const res = await $U.confirmUser(email.toLowerCase());
  if(res) {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email.toLowerCase())
      .then(() => {
        modal.value = false;
        $P.popUpHandler("Se envio exitosamente el correo de recuperacion");
      })
      .catch((error) => {
        console.log("error de recuperacion", error);
        modal.value = false;
        $P.popUpHandler("Hubo un error al enviar el correo de recuperacion", 0);
      });
  } else {
    $P.popUpHandler("No existe el correo electronico ingresado", 2);
    modal.value = false;
  }
  modalEmail.value = "";
}

const googleLogin = async () => {
  const auth = getAuth();
  try {
    const response = await GoogleAuth.signIn();
    console.log(response);
    const credential = GoogleAuthProvider.credential(response.authentication?.idToken);
    const userCredential = await signInWithCredential(auth, credential);
    console.log("Usuario autenticado:", userCredential.user);
    await $U.googleLogin(userCredential.user.accessToken);
  } catch(e) {
    console.log(e);
  }
};

const appleLogin = async () => {
  try {
    const response = await SignInWithApple.authorize({
      clientId: "com.mall-tienda-arr.service",
      redirectURI: "https://mall-55ad8.firebaseapp.com/__/auth/handler",
      scopes: 'email name',
      state: '12345'
    })
    console.log("response de SignInWithApple", response);
    const { identityToken, authorizationCode, givenName, familyName, email } = response.response;
    const provider = new OAuthProvider("apple.com");
    const credential = provider.credential({
      idToken: identityToken,
      rawNonce: authorizationCode
    });

    const auth = getAuth();
    const userCredential = await signInWithCredential(auth, credential);
    console.log("response de signInWithCredential", userCredential);
    console.log("access token", userCredential.user.accessToken);
    await $U.appleLogin(givenName, familyName, email, userCredential.user.accessToken);
  } catch(e) {
    console.log("error de apple", e);
  }
};

const isAppleLoginEnabled = () => {
  if ($C.deviceInfo?.platform === 'ios') return true;
  if ($C.deviceInfo?.platform === 'web' && $C.deviceInfo?.manufacturer === 'Apple Computer, Inc.') return true;
  return false;
};

onMounted(() => {
  GoogleAuth.initialize();
});
</script>

<template>
  <div class="login-container">
    <div v-if="$W.windowWidth > 768" class="login-img">
      <img src="../assets/images/registro.webp">
    </div>
    <div class="form-user">
      <img class="logo" src="../assets/logos/mall.svg" alt="logo">
      <p class="title">Te damos la bienvenida</p>
      <div class="form">
        <v-text-field :rules="[required]" v-model="formData.email" type="email" label="Correo electrónico" variant="outlined"></v-text-field>
        <v-text-field :rules="[required]" v-model="formData.password" :type="showPassw ? 'text' : 'password'" label="Contraseña" variant="outlined" @keyup.enter="loginUser" :append-inner-icon="showPassw ? 'mdi-eye' : 'mdi-eye-off'" @click:append-inner="showPassw = !showPassw"></v-text-field>
        <strong class="modal" @click="modal = true">¿Te olvidaste la contraseña?</strong>
        <div class="login-buttons">
          <button class="blue-button" @click.prevent="loginUser">Continuar</button>
          <div class="divider">
            <span>o</span>
          </div>
          <button @click="googleLogin" class="classic-button">
            <div class="btn-img">
              <img src="../assets/google.svg">
            </div>
            <span>Continuar con Google</span>
          </button>
          <button v-if="isAppleLoginEnabled()" @click="appleLogin" class="classic-button">
            <div class="btn-img">
              <img src="../assets/apple.svg">
            </div>
            <span>Continuar con Apple</span>
          </button>
        </div>
      </div>
      <div v-if="modal" class="reset-pass">
        <v-icon @click="modal = false">mdi-close</v-icon>
        <v-text-field v-model="modalEmail" label="Correo electrónico" variant="outlined" hide-details @keyup.enter="resetPasswd(modalEmail)"></v-text-field>
        <div @click="resetPasswd(modalEmail)" class="classic-button">Recuperar contraseña</div>
      </div>
      <div class="login-text">
        <p>¿No tenes una cuenta?</p>
        <strong><RouterLink to="/signin">Crear cuenta</RouterLink></strong>
      </div>
    </div>
  </div>
</template>